import angular from 'angular'

const context = require.context('../../', true, /\/partials\/.*\.html$/)

angular.module('TemplateCache', []).run([
  '$templateCache',
  function ($templateCache) {
    context.keys().forEach(function (tplPath) {
      let partialPath = tplPath.replace('./', '')
      $templateCache.put('/partial/' + partialPath, context(tplPath))
    })
  },
])
